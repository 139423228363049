import LocationPinIcon from '@ancon/wildcat-ui/shared/icons/location-pin-outline.svg'
import LinkIcon from '@ancon/wildcat-ui/shared/icons/arrow-up-right-outline.svg'
import EmailIcon from '@ancon/wildcat-ui/shared/icons/email-outline.svg'
import PhoneIcon from '@ancon/wildcat-ui/shared/icons/phone-volume-outline.svg'
import getFormattedPhoneNumber from '@ancon/wildcat-utils/user/getFormattedPhoneNumber'
import getStructuredOutletAddress from '@ancon/wildcat-utils/outlet/getStructuredOutletAddress'
import BodyText from '@ancon/wildcat-ui/web/orderweb/BodyText'
import Link from 'next/link'
import { OutletDetails, OutletListItem } from '@ancon/wildcat-types'

import InteractiveDiv from '../../../layout/components/InteractiveDiv'

import styles from './OutletInformation.module.scss'

type OutletInformationProps = {
  outletListItem: OutletListItem
  outletDetails: OutletDetails
}

export default function OutletInformation({
  outletListItem,
  outletDetails,
}: OutletInformationProps) {
  const { telephone, email } = outletDetails
  const { address } = outletListItem

  const structuredOutletAddress = getStructuredOutletAddress(address)

  function formatPhoneNumber() {
    try {
      return getFormattedPhoneNumber(telephone)
    } catch (err) {
      return telephone
    }
  }

  function handleNavigateToAddress() {
    const { longitude, latitude } = address
    window.open(`https://maps.google.com/?q=${latitude},${longitude}`, '_blank')
  }

  return (
    <div className={styles.container}>
      <InteractiveDiv
        className={styles.informationItem}
        onClick={handleNavigateToAddress}
      >
        <LocationPinIcon className={styles.infoContextIcon} />
        <div className={styles.contentContainer}>
          <BodyText className={styles.infoTitle}>
            {structuredOutletAddress.line1}
          </BodyText>
        </div>
        <LinkIcon />
      </InteractiveDiv>
      <BodyText color="body-1" className={styles.infoSubTitle}>
        {structuredOutletAddress.line2}
      </BodyText>
      {telephone && (
        <Link href={`tel:${telephone}`}>
          <div className={styles.informationItem}>
            <PhoneIcon className={styles.infoContextIcon} />
            <div className={styles.contentContainer}>
              <BodyText className={styles.infoTitle}>
                {formatPhoneNumber()}
              </BodyText>
            </div>
            <LinkIcon />
          </div>
        </Link>
      )}
      <Link href={`mailto:${email}`}>
        <div className={styles.informationItem}>
          <EmailIcon className={styles.infoContextIcon} />
          <div className={styles.contentContainer}>
            <BodyText className={styles.infoTitle}>{email}</BodyText>
          </div>
          <LinkIcon />
        </div>
      </Link>
    </div>
  )
}
