import { useState } from 'react'
import clsx from 'clsx'
import useTranslation from 'next-translate/useTranslation'
import ClockCircleOutlineIcon from '@ancon/wildcat-ui/shared/icons/clock-circle-outline.svg'
import BodyText from '@ancon/wildcat-ui/web/orderweb/BodyText'
import { OrderFormat, OutletListItem, OutletState } from '@ancon/wildcat-types'
import isOutletAcceptingPreOrders from '@ancon/wildcat-utils/outlet/isOutletAcceptingPreOrders'
import getOutletCurrentClosingTimeText from '@ancon/wildcat-utils/outlet/getOutletCurrentClosingTimeText'
import getOutletNextOpeningTimeText from '@ancon/wildcat-utils/outlet/getOutletNextOpeningTimeText'
import getListOutletOpeningHoursTable from '@ancon/wildcat-utils/outlet/getListOutletOpeningHoursTable'
import Button from '@ancon/wildcat-ui/web/orderweb/Button'

import Accordion from '../../../app/components/Accordion'

import styles from './OutletDetailsOpeningHours.module.scss'
import OutletOpeningHoursSet from './OutletOpeningHoursSet'

type OpeningHoursTableProps = {
  outletListItem: OutletListItem
}

function OpeningHoursTable({ outletListItem }: OpeningHoursTableProps) {
  const { t } = useTranslation('common')
  const openingHoursTable = getListOutletOpeningHoursTable(outletListItem)
  const [selectedSectionIndex, setSelectedSectionIndex] = useState(0)

  function getOrderFormatText(orderFormat: OrderFormat | undefined) {
    switch (orderFormat) {
      case OrderFormat.EatIn:
        return t('orderFormat.eatIn')
      case OrderFormat.TakeAway:
        return t('orderFormat.takeAway')
      case OrderFormat.TableOrder:
        return t('orderFormat.tableOrder')
      case OrderFormat.Delivery:
        return t('orderFormat.delivery')
      case OrderFormat.BoxPickup:
        return t('orderFormat.boxPickup')
      default:
        return ''
    }
  }

  return (
    <div className={styles.openingHoursContainer}>
      <div className={styles.openingHoursGroups}>
        {openingHoursTable.map(({ orderFormats }, index) => {
          const orderFormatIds = orderFormats.map(({ id }) =>
            getOrderFormatText(id),
          )
          const sectionKey = orderFormatIds.join('_')
          const sectionTitle = orderFormatIds.join(' / ')

          return (
            <Button
              key={sectionKey}
              variant="secondary"
              onClick={() => setSelectedSectionIndex(index)}
              className={clsx({
                [styles.selectedOpeningHourOrderFormat]:
                  selectedSectionIndex === index,
              })}
            >
              {sectionTitle}
            </Button>
          )
        })}
      </div>
      <OutletOpeningHoursSet item={openingHoursTable[selectedSectionIndex]} />
    </div>
  )
}

type OutletDetailsOpeningHoursProps = {
  outletListItem: OutletListItem
}

export default function OutletDetailsOpeningHours({
  outletListItem,
}: OutletDetailsOpeningHoursProps) {
  const { t } = useTranslation('common')

  const isOpened = outletListItem.state === OutletState.Open
  const isAcceptingPreOrders = isOutletAcceptingPreOrders(
    outletListItem.orderFormatSettings,
  )
  const isAcceptingOfflineOrders = !isOpened && isAcceptingPreOrders

  function outletDetailsModalTranslateFn(
    key: string,
    props?: Record<string, string>,
  ) {
    return t(`outletDetailsModal.${key}`, props)
  }

  const outletOpeningTime = isOpened
    ? getOutletCurrentClosingTimeText(
        outletListItem,
        outletDetailsModalTranslateFn,
      )
    : getOutletNextOpeningTimeText(
        outletListItem,
        outletDetailsModalTranslateFn,
      )

  function getOutletOpenStateText() {
    if (isOpened) {
      return t('outletDetailsModal.open')
    }
    if (isAcceptingOfflineOrders) {
      return t('outletDetailsModal.acceptingPreOrders')
    }
    return t('outletDetailsModal.closed')
  }

  return (
    <Accordion
      caretClassName={styles.caret}
      title={
        <div className={styles.headerContainer}>
          <ClockCircleOutlineIcon />
          <div className={styles.outletStatusContainer}>
            <BodyText
              className={clsx(styles.outletStatus, {
                [styles.outletStatusOpened]: isOpened,
                [styles.outletStateClosed]:
                  !isOpened && !isAcceptingOfflineOrders,
              })}
            >
              {getOutletOpenStateText()}
            </BodyText>
            <BodyText color="body-1" className={styles.outletOpeningHour}>
              {outletOpeningTime}
            </BodyText>
          </div>
        </div>
      }
    >
      <OpeningHoursTable outletListItem={outletListItem} />
    </Accordion>
  )
}
